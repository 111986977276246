const Version = () => {
  return (
    <>
      <div
        className="position-fixed end-0 bottom-0 pb-2 "
        style={{ zIndex: 10 }}
      >
        <strong className=" px-4 py-2 text-black">
          version {process.env.REACT_APP_VERSION}
        </strong>
      </div>
    </>
  );
};

export default Version;
