//import profile from "../../../images/user.jpg";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import profile from "../../../images/avatar/default-avatar.png";
import { logoGoVap } from "../../../logo";
import { selectorUser } from "../../../store/slices/userSlice";
import LogoutPage from "./Logout";
import { useState, useEffect } from "react";
import axiosClient from "../../../api/axiosClient";

const TopBar = (prop: any) => {
  const dataUser = useSelector(selectorUser);
  const { tenNguoiDung, anhDaiDien } = dataUser;

  const [mainTitle, setMainTitle] = useState("");
  const [hideLogo, setHideLogo] = useState<boolean>(false);
  const [, setTitleIndex] = useState("");
  const [, setTitleLogin] = useState("");
  const [, setTitleLoginSpan] = useState("");

  


  return (
    <div className="bg-white top-bar position-fixed border-bottom top-0 start-0 end-0">
      <div className="header-content">
        <nav className="navbar navbar-expand top-bar-nav py-0">
          <div className="d-flex align-items-center ps-4 top-bar-logo">
            {!hideLogo ? (
              <img
                alt="img"
                src={logoGoVap}
                className="img-fluid me-3"
                style={{ objectFit: "cover" }}
                width={45}
                height={45}
              />
            ) : null}
            <div className="fs-4 text-uppercase fw-bolder">
              {mainTitle ?? "Hệ thống vận hành, quản lý thư viện"}
            </div>
          </div>
          <div className="ms-auto top-bar-user-info">
            <div className="nav-header-right">
              <Dropdown className="dropdown header-profile2">
                <Dropdown.Toggle
                  variant=""
                  as="a"
                  className="nav-link i-false c-pointer py-2"
                >
                  <div className="header-info2 d-flex align-items-center px-0 py-0">
                   
                    <div className="d-flex align-items-center sidebar-info">
                      <div>
                        <span className="fw-bold d-block mb-0 top-bar-user-name fs-5">
                          {tenNguoiDung ? tenNguoiDung : "Người dùng mới"}
                        </span>
                      </div>
                      <i
                        className="fas fa-sort-down ms-4"
                        style={{ marginTop: -5 }}
                      ></i>
                    </div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className=" dropdown-menu">
                  {/* <Dropdown.Item className="dropdown-item" href={`/chi-tiet-nguoi-dung/${dataUser.idNguoiDung}`}>
                   */}
                  <Dropdown.Item
                    className="dropdown-item"
                    href={`/trang-ca-nhan`}
                  >
                    <div className="ai-icon">
                      <svg
                        id="icon-user1"
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-primary me-1"
                        width={18}
                        height={18}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                        <circle cx={12} cy={7} r={4} />
                      </svg>
                      <span className="ms-2">Thông tin</span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-item">
                    <LogoutPage />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default TopBar;
