import { paserParams } from "../until";
import axiosClient, { ResponseSuccess } from "./axiosClient";

export interface User {
	data: Array<{
		idNguoiDung?: string;
		maDangNhap?: string;
		email?: string;
		xacThucEmail?: string;
		soDienThoai?: string;
		anhDaiDien?: string;
		tenNguoiDung?: string;
		khoaDen?: string;
		lanTruyCapLoi?: string;
		idTrangThai?: string;
		idDonVi?: string;
		taoBoi?: string;
		taoLuc?: string;
		capNhatBoi?: string;
		capNhatLuc?: string;
		tenDonVi?: string;
		tenTrangThai?: string;
		idHocSinh?: string;
		tenLop?: string;
		tenDoiTuong?: string;
		maDoiTuong?: string;
	}>;
	totalRow?: number;
}
export interface UserInfo {
	idNguoiDung?: string;
	maDangNhap?: string;
	email?: string;
	xacThucEmail?: string;
	soDienThoai?: string;
	anhDaiDien?: string;
	tenNguoiDung?: string;
	khoaDen?: string;
	lanTruyCapLoi?: string;
	idTrangThai?: string;
	idDonVi?: string;
	taoBoi?: string;
	taoLuc?: string;
	capNhatBoi?: string;
	capNhatLuc?: string;
	tenDonVi?: string;
	tenTrangThai?: string;
	isAdmin?: boolean;
	idHocSinh?: string;
	tenLop?: string;
	tenDoiTuong?: string;
	maDoiTuong?: string;
	// idCho: Array<{
	//     idCho?: string,
	//     tenCho?: string
	// }>,
	nguoiDungCho: Array<{
		idCho?: string;
	}>;
}

export const layThongTinCuaToi = async () => {
	const path = "nguoi-dung";
	const result: ResponseSuccess<UserInfo> = await axiosClient.get(path, {});
	return result;
};

export const getListUser = async (
	PageNumber: number,
	PageSize: number = 25,
	MaDangNhap: string = "",
	Email: string = "",
	SoDienThoai: string = "",
	TenNguoiDung: string = "",
	IdDonVi: Array<any> = [],
	IdTrangThai: Array<any> = [],
	Startday: string = "",
	endday: string = ""
) => {
	const path = "nguoi-dung/items";
	const params = {
		PageNumber,
		PageSize,
		search: {
			MaDangNhap,
			Email,
			SoDienThoai,
			TenNguoiDung,
			IdDonVi,
			IdTrangThai,
			capNhatLuc: {
				start: Startday,
				end: endday,
			},
		},
	};
	const result: ResponseSuccess<User> = await axiosClient.get(path, {
		params: paserParams(params, undefined),
	});
	return result;
};
//list nhân viên
// //combo nhân viên quản lý
// export const getListNhanvien = async () => {
//     const path = "nguoi-dung/get-all";
//     const result: ResponseSuccess<Array<UserInfo>> = await axiosClient.get(path, {
//     });
//     return result;
// }

export const layThongTinNguoiDung = async (idNguoiDung: string) => {
	const path = "nguoi-dung/" + idNguoiDung;
	const result: ResponseSuccess<UserInfo> = await axiosClient.get(path, {});

	return result;
};

// export const getInfoUser = async (idNguoiDung: string) => {
//     const path = "nguoi-dung/" + idNguoiDung;
//     const result: ResponseSuccess<UserInfo> = await axiosClient.get(path, {
//     });
//     return result;
// }

export const addNewUser = async (
	MaDangNhap: string,
	MatKhau: string,
	Email: string,
	SoDienThoai: string,
	avt: string | BinaryData,
	TenNguoiDung: string,
	IdTrangThai: string | null,
	IdDonVi: string | null,
	idCho: Array<any> = [],
	IdDoiTuong: string,
	isAdmin: boolean
) => {
	const path = "nguoi-dung";
	var AnhDaiDien = avt !== "" ? avt : null;
	const params = {
		MaDangNhap,
		MatKhau,
		Email,
		SoDienThoai,
		AnhDaiDien,
		TenNguoiDung,
		IdTrangThai,
		IdDonVi,
		IdCho: idCho,
		IdDoiTuong,
		isAdmin,
	};
	const result: ResponseSuccess<UserInfo> = await axiosClient.post(
		path,
		paserParams(params, "")
	);
	return result;
};

//chỉnh sửa người dùng
export const editNewUser = async (
	IdNguoiDung: string,
	MaDangNhap: string,
	Email: string,
	SoDienThoai: string,
	AnhDaiDien: string,
	TenNguoiDung: string,
	KhoaDen: Date | string | null,
	IdTrangThai: string | null,
	IdDonVi: string | null,
	MatKhau?: string,
	IdDoiTuong?: string,
	isAdmin: boolean = false
) => {
	const path = "nguoi-dung";
	const params = {
		IdNguoiDung,
		MaDangNhap,
		MatKhau,
		Email,
		SoDienThoai,
		AnhDaiDien,
		TenNguoiDung,
		KhoaDen,
		IdTrangThai,
		IdDonVi,
		IdDoiTuong,
		isAdmin,
	};
	const result: ResponseSuccess<UserInfo> = await axiosClient.put(
		path,
		paserParams(params, "")
	);

	return result;
};
//chỉnh sửa một phần người dùng
export const editUserList = async (
	IdNguoiDung: string,
	MaDangNhap: string,
	Email: string,
	SoDienThoai: string,
	avt: string | BinaryData,
	TenNguoiDung: string,
	KhoaDen: Date | string | null,
	IdTrangThai: string | null,
	IdDonVi: string | null,
	MatKhau?: string,
	IdDoiTuong?: string,
	isAdmin: boolean = false
) => {
	const path = "nguoi-dung";
	var AnhDaiDien = avt !== "" ? avt : null;
	const params = {
		IdNguoiDung,
		MaDangNhap,
		MatKhau,
		Email,
		SoDienThoai,
		AnhDaiDien,
		TenNguoiDung,
		KhoaDen,
		IdTrangThai,
		IdDonVi,
		IdDoiTuong,
		isAdmin,
	};
	const result: ResponseSuccess<UserInfo> = await axiosClient.put(
		path,
		paserParams(params, "")
	);

	return result;
};

//xóa người dùng
export const DelUser = async (idNguoiDung: string) => {
	const path = "nguoi-dung/" + idNguoiDung;
	const result: ResponseSuccess<string> = await axiosClient.delete(path, {});
	return result;
};

//thêm người dùng qua file excel
export const addNewUserExcel = async (nguoiDung: Array<string>) => {
	const path = "nguoi-dung/excel";
	const params = {
		nguoiDung,
	};
	const result: ResponseSuccess<string> = await axiosClient.post(
		path,
		paserParams(params, "")
	);
	return result;
};

//lấy tất cả người dùng
export const getAllUser = async () => {
	const path = "nguoi-dung/get-all";
	const result: ResponseSuccess<Array<UserInfo>> = await axiosClient.get(
		path,
		{}
	);
	return result;
};

//lấy quyền của người dùng đó
export const getPermissionUser = async (idNguoiDung: string) => {
	const path = "quyen/quyen-nguoi-dung/" + idNguoiDung;
	const result: ResponseSuccess<Array<any>> = await axiosClient.get(path, {});
	return result;
};

export function getListAllTrangThaiMuonSach() {
	throw new Error("Function not implemented.");
}
